import React, { useContext, useState, useEffect } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import SubjectList from "./SubjectList";
import AuthContext from "../../store/authContext";
import { Disclosure } from "@headlessui/react";
import QuizList from "./QuizList";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";

const ExamCategery = ({ name, id }) => {
  const authContext = useContext(AuthContext);
  const [examCategoryData, setExamCategoryData] = useState([]);
  useEffect(() => {
    getExamCategoryList();
  }, []);
  const getExamCategoryList = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(
        `/administrator/exam-category-exam/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setExamCategoryData(response.data["allExamCategoryExam"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.message);
    }
  };
  return (
    <>
      {name === "Prelims Test Series" || name === "Mains Test Series" ? (
        <div className="my-3">
          <QuizList key={id} id={id} name={name} />
        </div>
      ) : (
        <>
          <Disclosure as="div" key={id}>
            {({ open }) => (
              <>
                <div className=" flow-root">
                  <Disclosure.Button className="w-full flex items-center justify-between ">
                    <span className="font-medium capitalize text-lg  text-black">
                      {name}
                    </span>
                    <span className="flex items-center">
                      {open ? (
                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </div>
                <Disclosure.Panel>
                  <div className="space-y-3 my-3">
                    {examCategoryData?.map((allExamCategoryExam) => (
                      <SubjectList
                        key={allExamCategoryExam["id"]}
                        name={allExamCategoryExam["name"]}
                        id={allExamCategoryExam["id"]}
                      />
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </>
      )}
    </>
  );
};

export default ExamCategery;
