import React, { useRef, useContext } from "react";
import { useParams } from "react-router";
import MicrostudyApi from "../api/MicrostudyApi";
import AuthContext from "../store/authContext";
import { toast } from "react-toastify";
const Createitem = () => {
  const InputChapterRef = useRef();
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const CreateChapter = async (e) => {
    e.preventDefault();
    try {
      const ChapterName = InputChapterRef.current.value;
      const token = authContext.token;
      const response = await MicrostudyApi.post(
        `/administrator/add-book`,
        {
          subjectId: id,
          name: ChapterName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        toast(response.data.message);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <div className="max-w-7xl mx-auto px-4 mt-48 sm:px-6 lg:px-8 h-full">
      <div className="min-h-full  py-36 lg:my-36 px-6  flex items-center justify-center ">
        <div className="max-w-md w-full">
          <form className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm ">
              <input
                id="chapterId"
                name="text"
                type="text"
                ref={InputChapterRef}
                required
                className="relative  rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Enter Chapter Name"
              />
            </div>
            <button
              onClick={CreateChapter}
              className=" relative w-full flex justify-center py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black "
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Createitem;
