import React from "react";
import CreateQuiz from "../components/testseries/CreateQuiz";
import QuizNotification from "../components/testseries/QuizNotification";
import ViewQuiz from "../components/testseries/ViewQuiz";
const TestSeries = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto px-4 h-full">
        <div className="min-h-full   flex items-center justify-center ">
          <div className="w-full">
            <div className="flex flex-col">
              <h1 className="mx-auto flex justify-center my-3 text-4xl font-bold">
                Test Series
              </h1>
              <QuizNotification />
              <div className="my-5"></div>
              <CreateQuiz />
              <div className="my-5"></div>
              <ViewQuiz />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestSeries;
