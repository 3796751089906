import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./Layouts/Dashboard";
import Signin from "./auth/Signin";
// import Signup from "./auth/Signup";
import FooterAdmin from "./components/Footers/FooterAdmin";
//Tostify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//contextProvider
import AuthContext from "./store/authContext";
import Sidebar from "./components/Sidebar/Sidebar";
import Createitem from "./Layouts/Createitem";
import Uploadchapter from "./components/fileUpload/Uploadchapter";
import UserTables from "./components/Cards/UserTables";
import UsersDetails from "./components/Cards/UsersDetails";
import Notification from "./Layouts/Notification";
import TestSeries from "./Layouts/TestSeries";
import CreateQuiz from "./Layouts/CreateQuiz";
const App = () => {
  const authContext = useContext(AuthContext);
  const isSignedin = authContext.isAuthenticated;
  return (
    <React.StrictMode>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isSignedin && <Sidebar />}
      <Switch>
        {/* <Route exact path="/Signup">
          {isSignedin ? <Redirect to="/dashboard" /> : <Signup />}
        </Route> */}

        <Route exact path="/">
          {isSignedin ? <Redirect to="/dashboard" /> : <Signin />}
        </Route>
        {isSignedin && (
          <div className="md:ml-64 bg-white">
            <div className="px-4 md:px-10 mx-auto w-full">
              <Route exact path="/dashboard">
                {isSignedin ? <Dashboard /> : <Redirect to="/" />}
              </Route>
              <Route
                path="/dashboard/all_users"
                exact
                component={UsersDetails}
              />
              <Route
                path="/dashboard/all_enrolled_users"
                exact
                component={UserTables}
              />
              <Route
                path="/dashboard/notifications"
                exact
                component={Notification}
              />
              <Route
                path="/dashboard/CreateChapter/:id"
                exact
                component={Createitem}
              />
              <Route
                path="/dashboard/CreateQuizList/:id"
                exact
                component={CreateQuiz}
              />
              <Route
                path="/dashboard/uploadchapter/:id"
                exact
                component={Uploadchapter}
              />
              <Route
                path="/dashboard/testseries/:id"
                exact
                component={TestSeries}
              />
              <FooterAdmin />
            </div>
          </div>
        )}
        {!isSignedin && (
          <Route path="*">
            <Redirect to="/" />
          </Route>
        )}
      </Switch>
    </React.StrictMode>
  );
};
export default App;
