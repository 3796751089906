import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";

export default function UserTables() {
  const [UserData, setUserData] = useState([]);
  const authContext = useContext(AuthContext);
  const totalusers = UserData.length;
  useEffect(() => {
    getUserDetails();
  }, []);
  const getUserDetails = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(`/administrator/usersdeatil`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setUserData(response.data["users"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <div className="max-w-7xl mx-auto  lg:mx-4 h-full">
      <div className="min-h-full   flex items-center justify-center ">
        <div className="w-full">
          <div className="flex  flex-col">
            <div className=" overflow-x-auto ">
              <div className="py-2 align-middle inline-block min-w-full ">
                <div className="lg:text-5xl text-xl my-4 font-bold text-center">
                  <h1 className="bg-indigo-400 text-black capitalize shadow-lg rounded-md p-2">
                    Total users <p className=" py-2">{totalusers}</p>
                  </h1>
                </div>

                <div className="shadow-xl overflow-hidden  my-10 border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-indigo-400 text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-lg font-semibold text-gray-900 capatalize tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-lg font-semibold text-gray-900 capatalize tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-lg font-semibold text-gray-900 capitalize  tracking-wider"
                        >
                          Phone number
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3  text-lg font-semibold text-gray-900 capitalize  tracking-wider"
                        >
                          Time
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-indigo-100 text-gray-900 divide-y-2 divide-white">
                      {UserData.length > 0 &&
                        UserData.map((person) => (
                          <tr key={person.paymentid}>
                            <td className="px-6 py-4 whitespace-nowrap text-base font-medium">
                              {person.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-base font-medium">
                              {person.email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-base font-medium">
                              {person.phone}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-right text-base font-medium">
                              {person.createdAt}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
