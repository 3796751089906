import React, { useContext, useState, useEffect } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import BookList from "./BookList";
import AuthContext from "../../store/authContext";
import { Disclosure } from "@headlessui/react";
import { toast } from "react-toastify";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
const SubjectList = ({ name, id }) => {
  const authContext = useContext(AuthContext);
  const [subjectData, setSubjectData] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, []);

  const getSubjectList = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(
        `/administrator/exam-category-subject/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSubjectData(response.data["AllExamSubject"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.message.message);
    }
  };

  return (
    <>
      <Disclosure as="div" key={id}>
        {({ open }) => (
          <>
            <div className=" flow-root">
              <Disclosure.Button className="w-full flex items-center justify-between ">
                <span className="font-medium capitalize text-base  text-left text-black">
                  {name}
                </span>
                <span className="flex items-center">
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </div>
            <Disclosure.Panel>
              <div className="space-y-3 my-3">
                {subjectData?.map((AllExamSubject) => (
                  <BookList
                    key={AllExamSubject["id"]}
                    name={AllExamSubject["name"]}
                    id={AllExamSubject["id"]}
                  />
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default SubjectList;
