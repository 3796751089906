import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";

const ChapterList = ({ name, id }) => {
  const authContext = useContext(AuthContext);

  var token = authContext.token;
  const deleteChapter = async () => {
    try {
      await MicrostudyApi.delete(`/administrator/delete-book/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        if (res.status === 201) {
          toast(res.data.message);
        } else {
          toast(res.data.message);
        }
      });
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <>
      <div className="flex items-center justify-between  mx-2 px-2 py-2 hover:bg-gray-50 rounded-md">
        <Link
          exact
          to={`/dashboard/uploadchapter/${id}`}
          className="font-medium flex  hover:font-semibold  capitalize text-sm hover:text-indigo-600 text-gray-900"
        >
          {name}
        </Link>
        <button
          onClick={() => deleteChapter(id)}
          className="hover:text-red-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default ChapterList;
