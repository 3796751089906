import React from "react";

import CreateNotification from "../components/notifications/CreateNotification";
import UpdateNotification from "../components/notifications/UpdateNotification";

const Notification = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto px-4 mt-48 sm:px-6 lg:px-8 h-full">
        <div className="min-h-full lg:my-36 px-6  flex items-center justify-center ">
          <div className="max-w-md w-full shadow p-5 rounded-md bg-indigo-100">
            <CreateNotification />
            <div className="border-t my-4 border-gray-500"></div>
            <UpdateNotification />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
