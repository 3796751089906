import React from "react";
import { useEffect, useContext, useState } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import ExamList from "./ExamList";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";

const Courses = () => {
  const authContext = useContext(AuthContext);
  const [courseData, setCourseData] = useState([]);
  const token = authContext.token;
  useEffect(() => {
    getcourseData();
  }, []);
  const getcourseData = async () => {
    try {
      const response = await MicrostudyApi.get("/administrator/courses", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setCourseData(response.data["courses"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <>
      <div className="space-y-3">
        {courseData?.map((courseDataObject) => (
          <ExamList
            key={courseDataObject["id"]}
            name={courseDataObject["name"]}
            id={courseDataObject["id"]}
          />
        ))}
      </div>
    </>
  );
};

export default Courses;
