import React, { useContext, useState, useEffect } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import ExamCategory from "./ExamCategory";
import AuthContext from "../../store/authContext";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";

const ExamList = ({ name, id }) => {
  const authContext = useContext(AuthContext);
  const [examData, setExamData] = useState([]);
  useEffect(() => {
    getExamList();
  }, []);
  const getExamList = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(
        `/administrator/fetch-exam/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setExamData(response.data["allExamCourse"]);
      } else {
        toast(response.statusText);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  return (
    <React.StrictMode>
      <Disclosure as="div" key={id}>
        {({ open }) => (
          <>
            <div className=" flow-root">
              <Disclosure.Button className="w-full flex items-center justify-between text-base text-gray-900 ">
                <span className="font-bold  capitalize text-lg  text-black">
                  {name}
                </span>
                <span className="flex items-center">
                  {open ? (
                    <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </div>
            <Disclosure.Panel>
              <div className="my-3 space-y-3">
                {examData?.map((allExamCourse) => (
                  <ExamCategory
                    key={allExamCourse["id"]}
                    name={allExamCourse["name"]}
                    id={allExamCourse["id"]}
                  />
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </React.StrictMode>
  );
};
export default ExamList;
