import React, { useState } from "react";
const userContext = React.createContext({
  responseCourseData: [],
  responseExamData: [],
  responseExamCategoryData: [],
  courseId: "",
  examId: "",
  examCategoryId: "",
  subjectId: "",
  bookId: "",
  getResponseCourseData: () => {},
  getResponseExamData: () => {},
  getResponseExamcategoryData: () => {},
  getCourseId: () => {},
  getExamId: () => {},
  getExamCategeryId: () => {},
  getSubjectId: () => {},
  getBookId: () => {},
});
export const UserContextProvider = (props) => {
  const [responseCourseData, setResponseCourseData] = useState([]);
  const [responseExamData, setResponseExamData] = useState([]);
  const [responseExamCategoryData, setResponseExamCategoryData] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [examId, setExamId] = useState("");
  const [examCatageryId, setExamCategeryId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [bookId, setBookId] = useState("");
  const responseCourseDataHandler = (responseCourseData) => {
    setResponseCourseData(responseCourseData);
  };
  const responseExamDataHandler = (responseExamData) => {
    setResponseExamData(responseExamData);
  };
  const responseExamCategoryDataHandler = (responseExamCategoryData) => {
    setResponseExamCategoryData(responseExamCategoryData);
  };
  const courseIdHandler = (courseId) => {
    setCourseId(courseId);
  };
  const examIdHandler = (examId) => {
    setExamId(examId);
  };
  const examCategoryIdHandler = (examCatageryId) => {
    setExamCategeryId(examCatageryId);
  };
  const subjectIdHandler = (subjectId) => {
    setSubjectId(subjectId);
  };
  const bookIdHandler = (bookId) => {
    setBookId(bookId);
  };
  const contextValue = {
    responseCourseData: responseCourseData,
    responseExamData: responseExamData,
    responseExamCategoryData: responseExamCategoryData,
    courseId: courseId,
    examId: examId,
    examCatageryId: examCatageryId,
    subjectId: subjectId,
    bookId: bookId,
    getResponseCourseData: responseCourseDataHandler,
    getResponseExamData: responseExamDataHandler,
    getResponseExamCategoryData: responseExamCategoryDataHandler,
    getCourseId: courseIdHandler,
    getExamId: examIdHandler,
    getExamCategeryId: examCategoryIdHandler,
    getSubjectId: subjectIdHandler,
    getBookId: bookIdHandler,
  };
  return (
    <userContext.Provider value={contextValue}>
      {props.children}
    </userContext.Provider>
  );
};

export default userContext;
