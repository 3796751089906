import React from "react";
import { useContext, useRef } from "react";
import AuthNavbar from "../components/Navbars/AuthNavbar";
import logo from "../img/apple-touch-icon.png";
import MicrostudyApi from "../api/MicrostudyApi";
import AuthContext from "../store/authContext";
const Signin = () => {
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const authContext = useContext(AuthContext);

  //Signin Handler
  const signinHandler = async (e) => {
    e.preventDefault();

    const email = emailInputRef.current.value;
    const password = passwordInputRef.current.value;

    try {
      const response = await MicrostudyApi.post(
        "/auth/administrator/signin",
        {
          email,
          password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      let expirationTime = new Date();
      expirationTime.setDate(expirationTime.getDate() + 1);
      if (response.status !== 201 || !response) {
        window.alert("user not found!");
      } else {
        authContext.Signin(
          response.data.token,
          response.data.refreshToken,
          expirationTime.toISOString()
        );
      }
    } catch (error) {
      window.alert(error.message);
    }
  };

  return (
    <>
      <AuthNavbar />
      <div className="min-h-full  my-20 lg:my-36 px-6  flex items-center justify-center ">
        <div className="max-w-md w-full">
          <div>
            <img alt="" className="mx-auto h-16 w-auto" src={logo} />
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              Sign in to your account
            </h2>
            {/* <p className="mt-2 text-center text-sm text-gray-600">
              <span className="px-1 text-base font-semibold">
                Don't have an account?
              </span>
              <Link
                exact="true"
                to="/signup"
                className="font-semibold text-red-500 text-xl hover:text-indigo-500"
              >
                Sign up
              </Link>
            </p> */}
          </div>
          <form className="my-6" onSubmit={signinHandler}>
            <div className="mt-2">
              <label htmlFor="email" className=" text-lg font-semibold">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                ref={emailInputRef}
                className=" relative block w-full px-3 py-2 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                required
              />
            </div>
            <div className="my-2">
              <label htmlFor="password" className="text-lg font-semibold">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                ref={passwordInputRef}
                required
                className="relative block w-full px-3 py-2 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
            <button
              type="submit"
              className="group my-4 relative w-full flex justify-center py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black "
            >
              Sign in
            </button>
          </form>
          {/* <div className="text-base text-center">
            <Link
              to="/signin/reset_password"
              className="font-semibold text-red-500 hover:text-indigo-500"
            >
              Forgot your password?
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default Signin;
