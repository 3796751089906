import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/favicon32.png";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import AuthContext from "../../store/authContext";
import Courses from "./Courses";
import { toast } from "react-toastify";
const Sidebar = () => {
  const authContext = useContext(AuthContext);

  ///signout handler
  const SignoutHandler = () => {
    try {
      authContext.Signout();
    } catch (error) {
      toast(error.message);
    }
  };
  const [collapseShow, setCollapseShow] = useState("hidden");

  return (
    <>
      <div className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-md bg-indigo-400 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap flex flex-wrap items-center  w-full mx-auto">
          {/* Toggler desktop*/}

          <div className="flex justify-between items-center">
            <div className="md:hidden ">
              <button
                type="button"
                onClick={() => setCollapseShow("bg-indigo-200 m-2 py-3 px-6")}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-700"
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <Link
              exact="true"
              to="/"
              className="flex-shrink-0 flex items-center justify-center text-3xl  py-2 font-bold rounded-md "
            >
              <img
                alt=""
                className="h-6 w-6 sm:visible invisible "
                src={logo}
              />
              <span className="text-black sm:mx-2 ">Microstudy</span>
            </Link>
          </div>
          <div
            className={
              "md:flex md:flex-col md:items-stretch   md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header mobile */}
            <div className="md:min-w-full md:hidden block my-3 ">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    exact="true"
                    to="/"
                    className="flex-shrink-0 flex items-center text-2xl  py-2 font-bold rounded-md "
                  >
                    <img alt="" src={logo} className="w-6 h-6" />
                    <span className="text-black  px-2">Microstudy</span>
                  </Link>
                </div>
                <div className="w-6/12 flex  right-0 justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-red-500 opacity-100 md:hidden px-3 text-xl leading-none bg-transparent "
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <XIcon className="h-6 w-6 " aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <ul className="md:flex-col md:min-w-full space-y-3 flex flex-col list-none">
              <li
                onClick={() => setCollapseShow("hidden")}
                className="items-center"
              >
                <Link
                  className=" text-lg font-bold block cursor-pointer text-black"
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              </li>

              <li
                onClick={() => setCollapseShow("hidden")}
                className="items-center"
              >
                <Link
                  className=" text-lg font-bold block cursor-pointer text-black"
                  to="/dashboard/all_users"
                >
                  All users
                </Link>
              </li>

              <li
                onClick={() => setCollapseShow("hidden")}
                className="items-center"
              >
                <Link
                  className=" text-lg font-bold block cursor-pointer text-black "
                  to="/dashboard/all_enrolled_users"
                >
                  Enrolled users
                </Link>
              </li>

              <li
                onClick={() => setCollapseShow("hidden")}
                className="items-center"
              >
                <Link
                  className=" text-lg font-bold block cursor-pointer text-black   "
                  to="/dashboard/notifications"
                >
                  Notifications
                </Link>
              </li>
              <li className="items-center">
                <Courses />
              </li>

              <li className="items-center">
                <button
                  onClick={SignoutHandler}
                  className="text-black flex space-x-2 items-center justify-center border-2 border-gray-700 rounded-md py-2 font-bold px-4  text-xl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 mr-1 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  Signout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
