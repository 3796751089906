import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";

const ViewQuiz = () => {
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const [QuestionData, setQuestionData] = useState([]);
  var token = authContext.token;
  useEffect(
    function () {
      getTestSeries();
    },
    [id]
  );

  const getTestSeries = async () => {
    try {
      const response = await MicrostudyApi.get(
        `/administrator/getallquestions/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setQuestionData(response.data["getAllQuestions"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  const handleDeleteQuiz = async (id) => {
    try {
      await MicrostudyApi.delete(`/administrator/delete-testseries/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        if (res.status === 201) {
          toast(res.data.message);
        } else {
          toast(res.data.message);
        }
      });
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <>
      <div key={id.toString()} className="bg-indigo-400 rounded-md px-5">
        <h1 className="mx-5 flex justify-center my-3 text-2xl font-bold">
          View and Delete Quiz
        </h1>
        {QuestionData?.map((ObjectData, index) => {
          const { questiontext, options, id } = ObjectData;
          return (
            <div key={index}>
              <div className="lg:flex lg:items-center my-3 border-2 border-gray-900 shadow bg-white rounded-md px-3 py-3 lg:justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="text-xl font-semibold leading-7 text-gray-900  sm:truncate">
                    <span className="mx-2 text-red-600 text-lg">
                      {index + 1}.
                    </span>
                    {questiontext}
                  </h2>
                  {options?.map((optionData, index) => {
                    return (
                      <>
                        <div
                          key={index.toString()}
                          className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
                        >
                          <div className="mt-2 flex items-center text-lg text-gray-900">
                            <span className="mx-2 text-gray-600 text-sm ">
                              {index + 1}.
                            </span>
                            {optionData?.optiontext}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="mt-5 flex lg:mt-0 lg:ml-4">
                  <span className="sm:ml-3">
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteQuiz(id);
                      }}
                      className="inline-flex items-center px-4 py-2  border border-transparent rounded-md shadow text-lg font-semibold text-gray-900 bg-red-400  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 mx-2 text-red-600 flex-shrink-0 mr-1.5 "
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                      Delete
                    </button>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default ViewQuiz;
