import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import Progress from "./ProgressBar";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";
const Uploadchapter = () => {
  const { id } = useParams();
  const [pdfFile, setPdfFile] = useState(null);
  const [filename, setFilename] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const authContext = useContext(AuthContext);

  const handleFileUpload = (event) => {
    event.preventDefault();
    if (event.target.files[0] === undefined) {
      return;
    } else {
      setPdfFile(event.target.files[0]);
      setFilename(event.target.files[0].name);
    }
  };
  const uploadPdfFile = (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("pdf", pdfFile);
      const token = authContext.token;

      MicrostudyApi.put(`/administrator/uploadchapter/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        },
      }).then((res) => {
        setTimeout(() => setUploadPercentage(0), 1000);
        toast(res.data.message);
      });
    } catch (err) {
      if (err.response.status !== 201 || !err.response) {
        toast("There was a problem with the server");
      } else {
        toast(err.response.data.msg);
      }
      setUploadPercentage(0);
      console.log(err.message);
    }
  };
  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen">
        <div className="min-h-full  px-6  flex items-center justify-center ">
          <div className="max-w-lg w-full  bg-gray-100 shadow-lg p-10 rounded-lg">
            <label className="block text-lg font-semibold text-gray-700">
              Select File
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-indigo-400 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <div className="flex text-lg text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer hover:text-base p-2 bg-gray-100 rounded-md font-semibold text-gray-900 hover:text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto h-10 w-10 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                      />
                    </svg>
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
              </div>
            </div>
            <span className=" flex text-gray-900 text-lg p-2">{filename}</span>
            <div className="relative w-full py-2 pl-3 pr-10  text-left cursor-default">
              <div className="ml-3 block   text-xl truncate">
                <Progress percentage={uploadPercentage} />
              </div>
              <button
                onClick={uploadPdfFile}
                className="ml-3 absolute inset-y-0 right-0 flex rounded-md font-semibold  text-lg py-4 px-4 items-center bg-indigo-500 text-gray-900  hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Uploadchapter;
