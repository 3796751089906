import React, { useContext, useState, useEffect } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";

const ViewQuizNotification = () => {
  const authContext = useContext(AuthContext);
  const [newNotification, setNewNotification] = useState([]);
  var token = authContext.token;
  useEffect(() => {
    ViewNotifications();
  }, []);

  const deleteNotification = async (id) => {
    try {
      await MicrostudyApi.delete(
        `/administrator/delete-test-notification/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) => {
        if (res.status === 201) {
          toast(res.data.message);
        } else {
          toast(res.data.message);
        }
      });
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  async function ViewNotifications() {
    try {
      const response = await MicrostudyApi.get(
        `/administrator/getnotification`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setNewNotification(response.data["notification"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  }
  return (
    <div>
      <div className="my-10">
        {newNotification?.map((prpos) => (
          <div
            key={prpos.id}
            className="relative w-full my-2 bg-white border py-4 border-gray-300 rounded-md shadow-sm pl-3 pr-10  text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <span className="ml-3 block truncate">{prpos.notification}</span>
            <button
              onClick={() => deleteNotification(prpos.id)}
              className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 hover:text-red-500 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewQuizNotification;
