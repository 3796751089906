import React, { useState, useContext } from "react";
import { useParams } from "react-router";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";
const CreateQuiz = () => {
  const { id } = useParams();

  const [questionTextHindi, setQuestionTextHindi] = useState(null);
  const [description, setDescription] = useState(null);
  const [questionText, setQuestionText] = useState(null);
  const [questionImg, setQuestionImg] = useState(null);
  const [imgfilename, setImgFilename] = useState("");
  const [correctOption, setcorrectOption] = useState("");
  //options state
  const [optionOne, setOptionOne] = useState("");
  const [optionTwo, setOptionTwo] = useState("");
  const [optionThree, setOptionThree] = useState("");
  const [optionFour, setOptionFour] = useState("");
  const [optionFive, setOptionFive] = useState("");
  const authContext = useContext(AuthContext);
  const token = authContext.token;

  const handleQuestionImgUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0] === undefined) {
      return;
    } else {
      setQuestionImg(e.target.files[0]);
      setImgFilename(e.target.files[0].name);
    }
  };
  const uploadQuestion = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("testnameId", id);
      formData.append("questiontext", questionText);
      formData.append("questiontexthindi", questionTextHindi);
      formData.append("questionImgURL", questionImg);
      formData.append("description", description);

      //Create Question  post APi
      const res = await MicrostudyApi.post(
        `/administrator/add-question`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 201) {
        toast(res.data.msg);
        const optnRes = await MicrostudyApi.post(
          `/administrator/add-option`,
          {
            questionId: res.data.data.id,
            optiontext1: optionOne,
            optiontext2: optionTwo,
            optiontext3: optionThree,
            optiontext4: optionFour,
            optiontext5: optionFive,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (optnRes.status === 201) {
          toast(optnRes.data.message);
        } else {
          toast(optnRes.data.msg);
        }
        //correct answer
        await MicrostudyApi.post(
          `/administrator/addanswer`,
          {
            questionId: res.data.data.id,
            correctanswer: correctOption,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((res) => {
          if (res.status === 201) {
            toast(res.data.message);
          } else {
            toast(res.data.message);
          }
        });
      } else {
        toast(res.data.data.msg);
      }
    } catch (err) {
      toast(err.message);
    }
    //create option
  };

  return (
    <>
      <div className="my-5 mx-5  md:col-span-2">
        <div className="shadow  p-5  bg-indigo-100 lg:p-10 sm:rounded-md sm:overflow-hidden">
          <div className=" w-full">
            <h1 className="text-4xl font-semibold text-indigo-600 text-center">
              Create Quiz
            </h1>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <label
                  htmlFor="question-text"
                  className="block text-lg my-1 font-medium text-gray-700"
                >
                  Question (in Text )
                </label>
                <input
                  type="text"
                  name="question-text"
                  id="question-text"
                  required={true}
                  onChange={(e) => setQuestionText(e.target.value)}
                  className=" block w-full text-base font-medium focus:ring-indigo-500  focus:border-indigo-500 rounded-md text-gray-700"
                  placeholder="Enter question here"
                />
              </div>
              <div className="col-span-3 sm:col-span-2">
                <label
                  htmlFor="question-text-hindi"
                  className="block text-lg my-1 font-medium text-gray-700"
                >
                  Question (in Hindi )
                </label>
                <input
                  type="text"
                  name="question-text-hindi"
                  id="question-text-hindi"
                  required={true}
                  onChange={(e) => setQuestionTextHindi(e.target.value)}
                  className=" block w-full text-base font-medium focus:ring-indigo-500  focus:border-indigo-500 rounded-md text-gray-700"
                  placeholder="Enter question here"
                />
              </div>
              <div className="col-span-3 sm:col-span-2">
                <div className="text-red-500 text-bold text-xl">OR</div>
                <label
                  htmlFor="question-text"
                  className="block text-lg font-medium text-gray-700"
                >
                  Question (in Image)
                </label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-indigo-300 bg-white border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer text-center px-2 flex bg-white rounded-md font-medium text-gary-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-400"
                      >
                        <svg
                          className="mx-auto h-12 w-12 text-indigo-400 "
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className=" text-xl text-indigo-400 flex justify-center items-center">
                          Upload a file
                        </span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleQuestionImgUpload}
                        />
                      </label>
                    </div>
                    <span className=" flex text-gray-900 text-lg p-2">
                      {imgfilename}
                    </span>
                    <p className="text-base text-gray-900">
                      PNG, JPG, JPEG up to 5MB
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-3 sm:col-span-2">
                <label
                  htmlFor="text"
                  className="block text-lg font-medium text-gray-700"
                >
                  Enter options
                </label>
                <div className="my-2 space-y-4">
                  <div className="flex items-center">
                    <input
                      type="text"
                      placeholder="Enter option 1"
                      onChange={(e) => setOptionOne(e.target.value)}
                      required={true}
                      className=" block  w-full text-base font-medium  focus:ring-indigo-500  focus:border-indigo-500 rounded-md text-gray-700"
                    ></input>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="text"
                      placeholder="Enter option 2"
                      onChange={(e) => setOptionTwo(e.target.value)}
                      required={true}
                      className="   block w-full text-base font-medium focus:ring-indigo-500  focus:border-indigo-500 rounded-md text-gray-700"
                    ></input>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="text"
                      placeholder="Enter option 3"
                      onChange={(e) => setOptionThree(e.target.value)}
                      required={true}
                      className="   block w-full text-base font-medium focus:ring-indigo-500  focus:border-indigo-500 rounded-md text-gray-700"
                    ></input>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="text"
                      placeholder="Enter option 4"
                      onChange={(e) => setOptionFour(e.target.value)}
                      required={true}
                      className="   block  w-full text-base font-medium focus:ring-indigo-500  focus:border-indigo-500  rounded-md text-gray-700"
                    ></input>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="text"
                      placeholder="Enter option 5"
                      onChange={(e) => setOptionFive(e.target.value)}
                      required={true}
                      className="   block  w-full text-base font-medium focus:ring-indigo-500  focus:border-indigo-500  rounded-md text-gray-700"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-span-3 sm:col-span-2">
                <label
                  htmlFor="correctOption"
                  className="block text-lg font-medium text-gray-700"
                >
                  Select Answer
                </label>
                <select
                  id="correctOption"
                  name="correctOption"
                  onChange={(e) => setcorrectOption(e.target.value)}
                  className="block capitalize  my-2 w-full text-base font-medium focus:ring-indigo-500  focus:border-indigo-500  rounded-md text-gray-700"
                >
                  <option
                    className="my-2 text-base  text-gray-900 rounded "
                    defaultValue=""
                  >
                    Select a Correct Option
                  </option>
                  <option
                    className="my-2 text-base  text-gray-900 rounded "
                    value={optionOne}
                  >
                    {optionOne || "Option 1"}
                  </option>
                  <option
                    className="my-2 text-base  text-gray-900 rounded "
                    value={optionTwo}
                  >
                    {optionTwo || "Option 2"}
                  </option>
                  <option
                    className="my-2 text-base  text-gray-900 rounded "
                    value={optionThree}
                  >
                    {optionThree || "Option 3"}
                  </option>
                  <option
                    className="my-2 text-base  text-gray-900 rounded "
                    value={optionFour}
                  >
                    {optionFour || "Option 4"}
                  </option>
                  <option
                    className="my-2 text-base  text-gray-900 rounded "
                    value={optionFive}
                  >
                    {optionFive || "Option 5"}
                  </option>
                </select>
              </div>
              <div className="col-span-3 sm:col-span-2">
                <label
                  htmlFor="question-text"
                  className="block text-lg my-1 font-medium text-gray-700"
                >
                  Description{" "}
                  <span className="text-red-600">(Max 255 character *)</span>
                </label>
                <textarea
                  name="question-text"
                  id="question-text"
                  row={4}
                  required={true}
                  onChange={(e) => setDescription(e.target.value)}
                  className=" block w-full text-base font-medium focus:ring-indigo-500  focus:border-indigo-500 rounded-md text-gray-700"
                  placeholder="Enter description  here"
                />
              </div>
            </div>
            <div className=" my-5 text-right">
              <button
                type="submit"
                onClick={uploadQuestion}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-lg font-semibold rounded-md text-gray-900 bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create Quiz
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuiz;
