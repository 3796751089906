import React from "react";

const Dashboard = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen">
      <div className="min-h-full px-6 flex items-center justify-center">
        <div className="w-full">
          <h1 className="flex justify-center lg:text-7xl text-xl font-bold capitalize text-indigo-600 items-center mx-auto md:my-auto">
            {" "}
            Welcome to dashboard
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
