import React, { useContext, useRef } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";
import ViewQuizNotification from "./ViewQuizNotification";

const QuizNotification = () => {
  const InputNotificationRef = useRef();
  const authContext = useContext(AuthContext);
  const CreateNotifications = async (e) => {
    e.preventDefault();
    try {
      const token = authContext.token;
      const Notification = InputNotificationRef.current.value;
      if (InputNotificationRef.current.value === "") {
        toast("please Write something !!");
      } else {
        const response = await MicrostudyApi.post(
          `/administrator/add-testportal-notification`,
          {
            notification: Notification,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 201) {
          toast(response.data.message);
          InputNotificationRef.current.value = "";
        } else {
          toast(response.data.message);
        }
      }
    } catch (error) {
      toast(error.response.data.message);
      InputNotificationRef.current.value = "";
    }
  };
  return (
    <>
      <div className="min-h-full  px-4 flex items-center justify-center ">
        <div className="w-full shadow p-5 rounded-md bg-indigo-100">
          <div className=" space-y-6">
            <h1 className="text-3xl font-semibold text-indigo-600  text-center">
              Create Quiz Notification
            </h1>
            <textarea
              id="about"
              name="about"
              ref={InputNotificationRef}
              rows={2}
              required="true"
              className="shadow-sm resize-none focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
              placeholder="Write something here"
            />

            <button
              onClick={CreateNotifications}
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black "
            >
              Create Notification
            </button>
            <ViewQuizNotification />
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizNotification;
