import React from "react";
import logo from "../../img/favicon32.png";
export default function Navbar(props) {
  return (
    <>
      <nav className="top-0 z-50 bg-gray-100 sticky  w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <div className="flex-shrink-0 flex items-center text-3xl px-3 py-2 font-bold rounded-md active:bg-green-500">
                <img alt="" className="px-2" src={logo} />
                Microstudy
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
