import React, { useContext, useRef } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";
const CreateNotification = () => {
  const InputNotificationRef = useRef();
  const authContext = useContext(AuthContext);
  const CreateNotifications = async (e) => {
    e.preventDefault();
    try {
      const token = authContext.token;
      const Notification = InputNotificationRef.current.value;
      if (InputNotificationRef.current.value === "") {
        toast("please Write something !!");
      } else {
        const response = await MicrostudyApi.post(
          `/administrator/add-notification`,
          {
            notificationtext: Notification,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 201) {
          toast(response.data.message);
          InputNotificationRef.current.value = "";
        } else {
          toast(response.data.message);
        }
      }
    } catch (error) {
      toast(error.response.data.message);
      InputNotificationRef.current.value = "";
    }
  };
  return (
    <>
      <div className=" space-y-6">
        <div className="rounded-md shadow-sm ">
          <textarea
            id="about"
            name="about"
            ref={InputNotificationRef}
            rows={3}
            required="true"
            className="shadow-sm resize-none focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
            placeholder="Write something here"
          />
        </div>
        <button
          onClick={CreateNotifications}
          className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black "
        >
          Create Notification
        </button>
      </div>
    </>
  );
};

export default CreateNotification;
