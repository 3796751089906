import React, { useContext, useState, useEffect } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import ChapterList from "./ChapterList";
import AuthContext from "../../store/authContext";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PlusSmIcon } from "@heroicons/react/solid";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";

const BookList = ({ name, id }) => {
  const authContext = useContext(AuthContext);
  const [subjectData, setSubjectData] = useState([]);

  useEffect(() => {
    getBookList();
  }, []);

  const getBookList = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(
        `/administrator/subject-book-category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSubjectData(response.data["AllBook"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  return (
    <>
      <Disclosure as="div" key={id}>
        {({ open }) => (
          <>
            <div className=" flow-root">
              <Disclosure.Button className="w-full flex items-center border-b border-gray-600 justify-between ">
                <span className="font-medium capitalize text-base  text-black">
                  {name}
                </span>
                <span className="flex items-center">
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </div>
            <Disclosure.Panel>
              <div className=" w-full flex flex-col justify-center text-gray-900 border-l-4 my-4 rounded-md border-gray-900 ">
                {subjectData?.map((AllBookObject) => (
                  <ChapterList
                    key={AllBookObject["id"]}
                    name={AllBookObject["name"]}
                    id={AllBookObject["id"]}
                  />
                ))}
                <Link
                  exact="true"
                  to={`/dashboard/CreateChapter/${id}`}
                  className="inline-flex items-center px-4 py-2 mt-4 mx-2  rounded-md shadow-md text-base font-medium text-indigo-600 bg-gray-50"
                >
                  <PlusSmIcon className="w-5 h-5" />
                  Create Chapter
                </Link>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default BookList;
